<template>
    <div>
        <h1>{{ $t('keep-quality') }} </h1>
        <b-row align-v="center" class="mt-5 ">
    
            <b-col class="">
                <div>
                    <h2 class="text-left">{{ $t('Auto-check-quality') }} </h2>
                    <p class="text-left hugep">
                        {{ $t('qaulity-check-description') }}
                    </p>
                </div>
            </b-col>
            <b-col cols="7">
    
                <div class="img-wrapper">
                    <video muted autoplay loop class=" video mx-auto img-radius  img-fluid d-block" src="@/app/assets/img/mp4/Quality_1.mp4" />
                </div>
            </b-col>
        </b-row>
        <b-row align-v="center" class="mt-5 pt-5 ">
    
            <b-col cols="7">
    
                <div class="img-wrapper">
                    <video muted autoplay loop class="video mx-auto img-radius  img-fluid d-block" src="@/app/assets/img/mp4/Quality_2.mp4" />
                </div>
            </b-col>
            <b-col class="">
                <div>
                    <h2 class="text-left">{{ $t('see-how-it-works') }} </h2>
                    <p class="text-left hugep">
                        {{ $t('check-your-process-description') }}
                    </p>
                </div>
            </b-col>
        </b-row>
        <b-row align-v="center" class="mt-5 pt-5 ">
            <b-col class="">
                <div>
                    <h2 class="text-left">{{ $t('scoring-team-level') }} </h2>
                    <p class="text-left hugep">
                        {{ $t('quality-description') }}
                    </p>
                </div>
            </b-col>
            <b-col cols="4">
    
                <div class="img-wrapper">
                    <img muted autoplay loop style="max-height: 400px" class="video mx-auto img-radius  img-fluid d-block" src="@/app/assets/img/Quality_3.png" />
                </div>
            </b-col>
    
        </b-row>
    </div>
    </template>
    
    <script>
    export default {
        name: 'FrontendQuality',
    
        data() {
            return {
    
            };
        },
    
        mounted() {
    
        },
    
        methods: {
    
        },
    };
    </script>
    
    <style lang="scss" scoped>
    .img-radius {
        border: 2px none #1C6EA4;
    }
    
    .img-wrapper {
        -webkit-box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
        border: 2px none #1C6EA4;
        border-radius: 0.75rem;
    }
    
    video {
        clip-path: inset(7px 7px);
    }
    
    .hugep {
        font-size: 20px;
    }
    </style>
    